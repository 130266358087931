"use strict";
(function ($, window) {
    var search = function (a) {};
    search.prototype = {
        initialize: function () {
            this.initSearchControl();
            this.initAutocomplete();
        },

        openSearchCtl: function () {
            document.querySelector('.search-wrap').classList.add('show');
            $("body").css("overflow", "hidden");
        },

        closeSearchCtl: function () {
            document.querySelector('.search-wrap').classList.remove('show');
            $("body").css("overflow", "auto");
        },
        
        initSearchControl: function () {
            document.querySelector('.search').addEventListener('click', function () {
                window.search.openSearchCtl();
                document.querySelector('.search__input').focus();
            });

            document.querySelector('.close').addEventListener('click', function () {
                window.search.closeSearchCtl();
                window.search.vautocomplete.clearTopic();
            });

            document.addEventListener('keyup', function (ev) {
                // escape key.
                if (ev.keyCode == 27) {
                    window.search.closeSearchCtl();
                }
            });
        },

        initAutocomplete: function () {
            Vue.component('autocompleteresult', {
                props: ['acdata'],
                template: '#vue-autocompleteresult-template',
                methods: {
                    insideDateFormat: function (el) {
                        if (el) {
                            return new moment(el).format("MMMM D, YYYY");
                        }
                    }
                },
            });

            this.vautocomplete = new Vue({
                el: "#vue-autocomplete",
                data: {
                    topic: "",
                    acresult: {}
                },
                methods: {
                    autocomplete: _.debounce(function () {
                        if (this.topic) {
                            var req = {
                                term: this.topic,
                            };
                            this.$http.get('/api/ac/', {
                                params: req
                            })
                            .then(function (response) {
                                window.search.vautocomplete.acresult = response.body;
                            }, function (response) {

                            });
                        } else {
                            window.search.vautocomplete.acresult = {};
                        }
                    }, 200),

                    clearTopic: function() {
                        this.topic = "";
                        this.autocomplete();
                    },

                    hasAcResults: function () 
                    {
                        return this.acresult.tags || this.acresult.posts || this.acresult.magazines
                    }

                }
            });
        }
    };
    window.search = new search();
    window.search.initialize();
})(jQuery, this, this.document, undefined);