"use strict";
(function ($, window) {
    var sitejs = function (a) {};
    sitejs.prototype = {
        initialize: function () {
            this.initMenu();
        },
        initMenu: function () {
            $('.toggleMenu, .bgMenuMobile').click(function () {
                $('.hamburguer').toggleClass('menu-open');
                $('.bgMenuMobile').toggleClass('show');
                $('.menu-mobile').toggleClass('show');
            });
        }
    };
    window.sitejs = new sitejs();
    window.sitejs.initialize();
})(jQuery, this, this.document, undefined);